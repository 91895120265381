import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BarChart2, Box, RefreshCw } from 'lucide-react';

const theme = {
  colors: {
    primary: '#1d2f6f', // Updated to match the provided image
    secondary: '#2c3e50',
    accent: '#e74c3c',
    background: '#ecf0f1',
    text: '#34495e',
    cardBg: '#ffffff',
    performanceCard: '#8390fa', // New color for Performance Tracker
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
  },
};

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
  
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  body {
    font-family: ${props => props.theme.fonts.body};
    color: ${props => props.theme.colors.text};
    background-color: ${props => props.theme.colors.background};
    box-sizing: border-box;
  }

  #root {
    height: 100%;
  }
`;

const AppWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.header`
  width: 100%;
  padding: 1rem 0;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
`;

const MainContent = styled.main`
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  padding: 4rem 2rem;
`;

const MainTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: ${props => props.theme.colors.secondary};
`;

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const AppCard = styled.div`
  background-color: ${props => props.isPerformanceTracker ? props.theme.colors.performanceCard : props.theme.colors.cardBg};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }
`;

const AppCardLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: contents;
`;

const AppIcon = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${props => props.isPerformanceTracker ? props.theme.colors.primary : props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const AppTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: ${props => props.isPerformanceTracker ? props.theme.colors.background : props.theme.colors.secondary};
`;

const AppDescription = styled.p`
  font-size: 1rem;
  color: ${props => props.isPerformanceTracker ? props.theme.colors.background : props.theme.colors.text};
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppWrapper>
        <Header>
          <HeaderContent>
            <HeaderTitle>Guerrilla Analytics</HeaderTitle>
          </HeaderContent>
        </Header>
        <MainContent>
          <MainTitle>Empower Your Decisions with Agile Analytics</MainTitle>
          <AppGrid>
            <AppCard isPerformanceTracker={true}>
              <AppCardLink href="https://performance.guerrillaanalytics.io" target="_blank" rel="noopener noreferrer">
                <AppIcon isPerformanceTracker={true}>
                  <BarChart2 size="60%" color={theme.colors.background} />
                </AppIcon>
                <AppTitle isPerformanceTracker={true}>Performance Tracker</AppTitle>
                <AppDescription isPerformanceTracker={true}>Monitor and analyze your business performance in real-time.</AppDescription>
              </AppCardLink>
            </AppCard>
            <AppCard>
              <AppIcon>
                <Box size="60%" color={theme.colors.cardBg} />
              </AppIcon>
              <AppTitle>Placeholder App 1</AppTitle>
              <AppDescription>Description for placeholder app 1 goes here.</AppDescription>
            </AppCard>
            <AppCard>
              <AppIcon>
                <RefreshCw size="60%" color={theme.colors.cardBg} />
              </AppIcon>
              <AppTitle>Placeholder App 2</AppTitle>
              <AppDescription>Description for placeholder app 2 goes here.</AppDescription>
            </AppCard>
          </AppGrid>
        </MainContent>
      </AppWrapper>
    </ThemeProvider>
  );
};

export default App;